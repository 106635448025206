import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';

const QuoteV1 = ({
  authorJobTitleColor,
  className,
  drawingWidth,
  quoteEntity,
}) => {
  const clientQuoteCss = css`
    position: relative;
    display: flex;
    flex-direction: row;
    font-family: ${fontFamilies.roboto};

    img {
      align-self: center;
      margin-right: ${uc('20px')};
    }

    figure {
      max-width: ${uc(`${730 - parseInt(drawingWidth, 10)}px`)};
    }

    @media (${breakpoints.tablet}) {
      width: auto;
    }
  `;

  const verticalClientQuoteCss = css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${uc('300px')};
    font-family: ${fontFamilies.roboto};

    figcaption {
      padding-top: ${uc('25px')};
    }
  `;

  const verticalFigureCss = css`
    display: flex;
    flex-direction: row;
    font-family: ${fontFamilies.roboto};
  `;

  const blockquoteCss = css`
    max-width: ${uc('410px')};
    margin: ${uc('0 0 20px 0')};
    padding: 0;
    color: ${colors.lightGray.five};
    font-weight: ${fontWeights.medium};
    line-height: ${lineHeights.copy};
  `;

  const verticalBlockquoteCss = css`
    max-width: ${uc('410px')};
    margin: ${uc('0 0 0 0')};
    padding: 0;
    color: ${colors.lightGray.five};
    line-height: ${lineHeights.copy};
  `;

  const authorTitleCss = css`
    color: ${authorJobTitleColor};
  `;

  const quoteAuthorCss = css`
    margin-bottom: ${uc('6px')};
    color: ${colors.darkGray.three};
    font-family: ${fontFamilies.roboto};
  `;

  const figcaptionCss = css`
    font-weight: ${fontWeights.bold};
  `;

  if (quoteEntity.imageOrientation === 'vertical') {
    return (
      <div css={verticalClientQuoteCss} className={className}>
        <blockquote
          css={verticalBlockquoteCss}
        >{`"${quoteEntity.quoteText}"`}</blockquote>

        <figure css={verticalFigureCss}>
          {quoteEntity.quoteImage && quoteEntity.quoteImage.asset && (
            <Image
              src={sanityImage(quoteEntity.quoteImage)
                .auto('format')
                .width(drawingWidth)
                .url()}
            />
          )}
          <figcaption css={figcaptionCss}>
            <div css={quoteAuthorCss}>{quoteEntity.author}</div>
            <div css={authorTitleCss}>{quoteEntity.authorJobTitle}</div>
          </figcaption>
        </figure>
      </div>
    );
  }

  return (
    <div css={clientQuoteCss} className={className}>
      {quoteEntity.quoteImage && quoteEntity.quoteImage.asset && (
        <Image
          src={sanityImage(quoteEntity.quoteImage)
            .auto('format')
            .width(drawingWidth)
            .url()}
        />
      )}

      <figure>
        <blockquote
          css={blockquoteCss}
        >{`"${quoteEntity.quoteText}"`}</blockquote>
        <figcaption css={figcaptionCss}>
          <div css={quoteAuthorCss}>{quoteEntity.author}</div>
          <div css={authorTitleCss}>{quoteEntity.authorJobTitle}</div>
        </figcaption>
      </figure>
    </div>
  );
};

QuoteV1.propTypes = {
  authorJobTitleColor: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  drawingWidth: PropTypes.number,
  quoteEntity: PropTypes.shape({
    quoteText: PropTypes.string,
    author: PropTypes.string,
    authorJobTitle: PropTypes.string,
    quoteImage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}).isRequired,
    ]),
    imageOrientation: PropTypes.string,
  }),
};

QuoteV1.defaultProps = {
  authorJobTitleColor: colors.darkGray.one,
  className: '',
  drawingWidth: 110,
  quoteEntity: {
    quoteText: '',
    author: '',
    authorJobTitle: '',
    quoteImage: '',
    imageOrientation: 'horizontal',
  },
};

export default QuoteV1;
