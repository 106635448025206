import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import { Heading3 } from '../../atoms/Headings/Headings';
import Card from '../../atoms/Card/Card';
import sanityImage from '../../../utils/sanityImage';
import {
  breakpoints,
  buttonSpacing,
  colors,
  fontSizes,
  pageDimensions,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';
import Image from '../../atoms/Image/Image';
import QuoteV1 from '../Quote/QuoteV1';
import AnimatedButton from '../../atoms/Buttons/AnimatedButton/AnimatedButton';
import VideoButton from '../../atoms/Buttons/VideoButton/VideoButton';
import Section from '../Section/Section';

const ClientCarouselCardV1 = ({
  animatedButton,
  bgImage,
  clientEntity,
  heading,
  quoteEntity,
  sectionStyles,
  videoButton,
  ...props
}) => {
  const cardCss = css`
    position: relative;
    z-index: -1;
    display: flex;
    justify-content: center;
    width: ${pageDimensions.desktopWidth};
    height: ${uc('455px')};
    margin: 0 auto;
    overflow: hidden;

    @media (${breakpoints.largeTablet}) {
      width: calc(100% - ${uc('40px')});
      height: auto;
    }
  `;

  const backgroundCss = css`
    position: absolute;
    left: 0;
    width: 37.5%;
    height: 100%;
    background-size: cover;
    clip-path: polygon(0 0, 74% 0, 100% 100%, 0% 100%);

    @media (${breakpoints.largeTablet}) {
      width: 100%;
      clip-path: none;
    }
  `;

  const cardContentCss = css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    margin-left: 37%;
    padding: ${uc('0 100px 0 72px')};

    @media (${breakpoints.largeTablet}) {
      width: 100%;
      margin-left: 0;
      padding: ${uc('48px')};
      background-color: #fffc;
    }
  `;

  const heading3Css = css`
    position: relative;
    top: 10px;
    max-width: ${uc('668px')};
    font-size: ${fontSizes.twentyThree};
  `;

  const clientImageCss = css`
    position: absolute;
    top: ${uc('-16px')};
    left: ${uc('-132px')};
    width: ${uc('140px')};
    height: ${uc('140px')};
    background: ${colors.white};
    border-radius: 50%;
    box-shadow: ${shadows.large};
    transform: translateX(-50%);
    object-fit: contain;
  `;

  const quoteCss = css`
    position: relative;
    margin-bottom: ${uc('20px')};

    @media (${breakpoints.largeTablet}) {
      img {
        display: none;
      }
    }
  `;

  const buttonContainerCss = css`
    position: relative;
    display: flex;

    .video-button {
      margin-right: ${buttonSpacing.marginRight};

      img {
        display: inline-block;
      }
    }

    @media (${breakpoints.mobile}) {
      display: block;

      button,
      a {
        display: block;
        width: 100%;
        margin-right: 0;
      }
    }
  `;

  // react-slick adds a style prop, so removing here
  const cleanProps = Object.assign({}, props);
  delete cleanProps.style;

  const backgroundImage = {
    backgroundImage: `url(${sanityImage(bgImage)
      .auto('format')
      .width(600)
      .url()})`,
  };

  return (
    <Section sectionStyles={sectionStyles}>
      <Card css={cardCss} className="carousel-card">
        <div css={backgroundCss} style={backgroundImage} />
        <div css={cardContentCss}>
          <Heading3 css={heading3Css}>
            {clientEntity.clientLogoColor && (
              <Image
                css={clientImageCss}
                src={sanityImage(clientEntity.clientLogoColor)
                  .auto('format')
                  .width(150)
                  .url()}
                alt={clientEntity.clientLogoColor.alt}
              />
            )}
            {heading}
          </Heading3>
          <QuoteV1 css={quoteCss} quoteEntity={quoteEntity} {...cleanProps} />
          <div css={buttonContainerCss}>
            {videoButton && videoButton.buttonText && (
              <VideoButton className="video-button" {...videoButton}>
                {videoButton.buttonText}
              </VideoButton>
            )}
            {animatedButton && (
              <AnimatedButton {...animatedButton}>
                {animatedButton && animatedButton.link.text}
              </AnimatedButton>
            )}
          </div>
        </div>
      </Card>
    </Section>
  );
};

ClientCarouselCardV1.propTypes = {
  animatedButton: PropTypes.shape({ link: {} }),
  bgImage: PropTypes.shape({}),
  heading: PropTypes.string,
  clientEntity: PropTypes.shape({
    clientLogoColor: PropTypes.shape({
      alt: PropTypes.shape({}),
    }),
  }),
  quoteEntity: PropTypes.shape({}),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  videoButton: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
};

ClientCarouselCardV1.defaultProps = {
  animatedButton: null,
  bgImage: {},
  heading: '',
  clientEntity: {},
  quoteEntity: {},
  sectionStyles: {},
  videoButton: null,
};

export default ClientCarouselCardV1;
